<template>
  <Layout :bgColor="true">
    <div class="rule-model-bg">
      <div class="detail rule-width"
           v-if="detail">
        <h2 class="tx-c tx-flow">{{detail.title}}</h2>
        <div class="small-info flex flex-sb">
          <h3>{{detail.release_time || '-'}}</h3>
          <h3>作者：{{detail.author || '-'}}</h3>
        </div>
        <div class="content"
             v-html="detail.content"></div>
        <div class="bottom-btn flex flex-sb">
          <div class="button flex flex-c flex-align"
               @click="$router.go(-1)">
            <img src="@/assets/icon/zuo.png"
                 alt="">
            <h4>返回</h4>
          </div>
        </div>
      </div>
    </div>
  </Layout>
</template>

<script>
import Mixins from './mixins/mixins'
import Layout from '@/components/layout/index'
import { parseTime } from '@/utils/index.js'
export default {
  components: {
    Layout,
  },
  data() {
    return {
      detail: null,
    }
  },
  mixins: [Mixins],
  created() {
    if (this.$route.query.id) {
      this.getContent()
    }
  },
  methods: {
    getContent() {
      let params = {}
      params.id = this.$route.query.id
      this.$http.getDetail(params).then((res) => {
        this.detail = res
        this.detail.release_time = parseTime(this.detail.release_time)
      })
    },
  },
}
</script>
<style lang="scss">
.detail {
  padding: 70px 40px;
  background: #fff;
  border-radius: 18px;
  min-height: 500px;
  .small-info {
    margin: 50px 0;
    color: #bbbbbb;
  }
  .content {
    min-height: 300px;
  }
  .bottom-btn {
    margin-top: 50px;
    .button {
      cursor: pointer;
      color: #fff;
      padding: 8px 40px;
      background: #57a9e4;
      border-radius: 10px;
      img {
        height: 20px;
        margin-right: 10px;
      }
      &:hover {
        background: #3989c2;
      }
    }
  }
}
</style>