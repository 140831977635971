/**
 * Created by PanJiaChen on 16/11/18.
 */

/**
 * Parse the time to string
 * @param {(Object|string|number)} time
 * @param {string} cFormat
 * @returns {string | null}
 */
export function parseTime(time, cFormat) {
  if (arguments.length === 0 || !time) {
    return null
  }
  const format = cFormat || '{y}-{m}-{d} {h}:{i}:{s}'
  let date
  if (typeof time === 'object') {
    date = time
  } else {
    if ((typeof time === 'string')) {
      if ((/^[0-9]+$/.test(time))) {
        // support "1548221490638"
        time = parseInt(time)
      } else {
        // support safari
        // https://stackoverflow.com/questions/4310953/invalid-date-in-safari
        time = time.replace(new RegExp(/-/gm), '/')
      }
    }

    if ((typeof time === 'number') && (time.toString().length === 10)) {
      time = time * 1000
    }
    date = new Date(time)
  }
  const formatObj = {
    y: date.getFullYear(),
    m: date.getMonth() + 1,
    d: date.getDate(),
    h: date.getHours(),
    i: date.getMinutes(),
    s: date.getSeconds(),
    a: date.getDay()
  }
  const time_str = format.replace(/{([ymdhisa])+}/g, (result, key) => {
    const value = formatObj[key]
    // Note: getDay() returns 0 on Sunday
    if (key === 'a') { return ['日', '一', '二', '三', '四', '五', '六'][value ] }
    return value.toString().padStart(2, '0')
  })
  return time_str
}
/**
 * @param {number} time
 * @param {string} option
 * @returns {string}
 */
export function formatTime(time, option) {
  if (('' + time).length === 10) {
    time = parseInt(time) * 1000
  } else {
    time = +time
  }
  const d = new Date(time)
  const now = Date.now()

  const diff = (now - d) / 1000

  if (diff < 30) {
    return '刚刚'
  } else if (diff < 3600) {
    // less 1 hour
    return Math.ceil(diff / 60) + '分钟前'
  } else if (diff < 3600 * 24) {
    return Math.ceil(diff / 3600) + '小时前'
  } else if (diff < 3600 * 24 * 2) {
    return '1天前'
  }
  if (option) {
    return parseTime(time, option)
  } else {
    return (
      d.getMonth() +
      1 +
      '月' +
      d.getDate() +
      '日' +
      d.getHours() +
      '时' +
      d.getMinutes() +
      '分'
    )
  }
}

/**
 * @param {string} url
 * @returns {Object}
 */
export function param2Obj(url) {
  const search = decodeURIComponent(url.split('?')[1]).replace(/\+/g, ' ')
  if (!search) {
    return {}
  }
  const obj = {}
  const searchArr = search.split('&')
  searchArr.forEach(v => {
    const index = v.indexOf('=')
    if (index !== -1) {
      const name = v.substring(0, index)
      const val = v.substring(index + 1, v.length)
      obj[name] = val
    }
  })
  return obj
}
/**
 * 秒转天
 * @param {number} msd
 * @returns {string}
 */
export function secondToDate(msd,countdown) {
  let now = new Date().getTime(),time = countdown?(+msd)*1000 - now:(+msd),data={};
  if(time<0) return 0;
  if (null != time && "" != time) {
      if (time >= 60 * 60 && time < 60 * 60 * 24) {
         data.day = 0
         data.h =  parseInt(time / 3600.0)  
         data.m = parseInt((parseFloat(time / 3600.0) - parseInt(time / 3600.0)) * 60)
      } else if (time >= 60 * 60 * 24) {
         data.day = parseInt(time / 3600.0/24)
         data.h =  parseInt((parseFloat(time / 3600.0/24)-parseInt(time / 3600.0/24))*24)  
         data.m = parseInt((parseFloat(time / 3600.0) - parseInt(time / 3600.0)) * 60)
      }
  }
  return data;
}
export function secText(msd) {
  let text ='',temp = secondToDate(msd,true)
  text =  `${temp.day} 天 ${temp.h} : ${temp.m}`
  if(!temp){
    text = 0
  }
  return text;
}
//  获取本周的第一天----最后一天
export function getCurrentWeek() {
    function getDateStr3(date) {
        var year = "";
        var month = "";
        var day = "";
        var now = date;
        year = ""+now.getFullYear();
        if((now.getMonth()+1)<10){
          month = "0"+(now.getMonth()+1);
        }else{
          month = ""+(now.getMonth()+1);
        }
        if((now.getDate())<10){
          day = "0"+(now.getDate());
        }else{
          day = ""+(now.getDate());
        }
        return year+"-"+month+"-"+day;
    }

    let AddWeekCount = 0//0是本周，-1上一周，1下一周
    //起止日期数组  
    var startStop = new Array(); 
    //一天的毫秒数  
    var millisecond = 1000 * 60 * 60 * 24; 
    //获取当前时间  
    var currentDate = new Date();
    //相对于当前日期AddWeekCount个周的日期
    currentDate = new Date(currentDate.getTime() + (millisecond * 7*AddWeekCount));
    //返回date是一周中的某一天
    var week = currentDate.getDay(); 
    //返回date是一个月中的某一天  
    var month = currentDate.getDate();
    //减去的天数  
    var minusDay = week != 0 ? week - 1 : 6; 
    //获得当前周的第一天  
    var currentWeekFirstDay = new Date(currentDate.getTime() - (millisecond * minusDay)); 
    //获得当前周的最后一天
     var currentWeekLastDay = new Date(currentWeekFirstDay.getTime() + (millisecond * 6));
    //添加至数组  
    startStop.push(getDateStr3(currentWeekFirstDay)); 
    startStop.push(getDateStr3(currentWeekLastDay)); 
    
    return startStop
}

//  获取本月的第一天----最后一天
export function getCurrentMonths() {
    let date =  new Date();
    let arr = []
    arr[0] = getCurrentMonthFirstDay(date)
    arr[1] = getCurrentMonthLastDay(date)
    return arr
}





//  获取本周的第一天
//   返回格式: YYYY-mm-dd
//   例子: 当日为: 2020-11-27
//   返回日期为: 2020-11-23

export function getCurrentWeekFirstDay(date) {
    let weekFirstDay = new Date(date - (date.getDay() - 1) * 86400000)
    let firstMonth = Number(weekFirstDay.getMonth()) + 1

    if (firstMonth < 10) {
        firstMonth = '0' + firstMonth
    }
    let weekFirstDays = weekFirstDay.getDate();
    if (weekFirstDays < 10) {
        weekFirstDays = '0' + weekFirstDays;
    }
    return weekFirstDay.getFullYear() + '-' + firstMonth + '-' + weekFirstDays;
}


//   获取本周的最后一天
//  返回格式: YYYY-mm-dd
//  例子: 当日为: 2020-11-27
//  返回日期为: 2020-11-29

export function getCurrentWeekLastDay(date) {
    let weekFirstDay = new Date(date - (date.getDay() - 1) * 86400000)
    let weekLastDay = new Date((weekFirstDay / 1000 + 6 * 86400) * 1000)
    let lastMonth = Number(weekLastDay.getMonth()) + 1
    if (lastMonth < 10) {
        lastMonth = '0' + lastMonth
    }
    let weekLastDays = weekLastDay.getDate();
    if (weekLastDays < 10) {
        weekLastDays = '0' + weekLastDays;
    }
    return weekFirstDay.getFullYear() + '-' + lastMonth + '-' + weekLastDays;
}


// 获取当前月的第一天
// 返回格式: YYYY-mm-dd
// 例子: 当日为: 2020-11-27
//  返回日期为: 2020-11-01

export function getCurrentMonthFirstDay() {
    let date = new Date();
    // console.log(date);
    date.setDate(1);
    let month = parseInt(date.getMonth() + 1);
    let day = date.getDate();
    if (month < 10) {
        month = '0' + month
    }
    if (day < 10) {
        day = '0' + day
    }
    return date.getFullYear() + '-' + month + '-' + day;
}


// 获取当前月的最后一天
// 返回格式: YYYY-mm-dd
//  例子: 当日为: 2020-11-27
// 返回日期为: 2020-11-30

export function getCurrentMonthLastDay() {
    let date = new Date();
    let currentMonth = date.getMonth();
    let nextMonth = ++currentMonth;
    let nextMonthFirstDay = new Date(date.getFullYear(), nextMonth, 1);
    let oneDay = 1000 * 60 * 60 * 24;
    let lastTime = new Date(nextMonthFirstDay - oneDay);
    let month = parseInt(lastTime.getMonth() + 1);
    let day = lastTime.getDate();
    if (month < 10) {
        month = '0' + month
    }
    if (day < 10) {
        day = '0' + day
    }
    return date.getFullYear() + '-' + month + '-' + day;
}
// 可选直辖市/省份
export function cities() {
  return [
      '北京市',
      '天津市',
      '上海市',
      '重庆市',
      '河北省',
      '山西省',
      '台湾省',
      '辽宁省',
      '吉林省',
      '黑龙江省',
      '江苏省',
      '浙江省',
      '安徽省',
      '福建省',
      '江西省',
      '山东省',
      '河南省',
      '湖北省',
      '湖南省',
      '广东省',
      '甘肃省',
      '四川省',
      '贵州省',
      '海南省',
      '云南省',
      '青海省',
      '陕西省',
      '广西壮族自治区',
      '西藏自治区',
      '宁夏回族自治区',
      '新疆维吾尔自治区',
      '内蒙古自治区',
      '澳门特别行政区',
      '香港特别行政区',
  ]
}
