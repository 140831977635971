
import Swiper from 'swiper'
import 'swiper/dist/css/swiper.min.css'
import 'swiper/dist/js/swiper.min'

export default {
  data () {
    return {
      newsList: [],
      newsListTop: [],
      page: 1,
      limit: 10,
      total: null,
    }
  },
  beforeMount () {
    this.getNews()
    this.getNewsTop()

  },
  filters: {
    parse (value, type) {
      if ((typeof value === 'number') && (value.toString().length === 10)) {
        value = value * 1000
      }
      let date = new Date(parseInt(value))

      let content = ''
      if (type == 'y') {
        content = date.getFullYear()
      }
      if (type == 'MM') {
        content = (date.getMonth() + 1).toString().padStart(2, '0')
      }
      if (type == 'd') {
        content = date.getDate().toString().padStart(2, '0')
      }
      if (type == 'time') {
        let h = date.getHours().toString().padStart(2, '0')
        let m = date.getMinutes().toString().padStart(2, '0')
        let s = date.getSeconds().toString().padStart(2, '0')
        content = h + ':' + m + ':' + s
      }
      return content
    },
  },
  methods: {
    getNews () {
      this.$store.commit('showLoading')
      let data = {
        module: 0, //所属模块：1-上音，2-央音，3-国音，4-英皇，0-所有模块
        type: 1, //请求类型：-1-全部，1-最新，2-最热
        column: 0, //所属栏目：1-考级，2-大赛，0-不分栏目
        release_terminal: 2, //发布端口：1-乐考通，2-云音符官网，默认为乐考通
        is_top: 0, //是否置顶：0-否，1-是，-1-全部（不区分是否置顶）
        is_choiceness: -1, //是否精选：0-否，1-是，-1-全部（不区分是否精选）
        page: this.page,
        limit: this.limit,
      }
      this.$http.getArticles(data).then((res) => {
        res.list.forEach(element => {
          this.newsList.push(element)
        });
        // this.newsList = res.list
        this.total = res.total
        this.$store.commit('hideLoading')
      }).catch(err => {
        console.log(err)
        this.$store.commit('hideLoading')
      })
    },
    getNewsTop () {
      this.$store.commit('showLoading')
      let data = {
        module: 0, //所属模块：1-上音，2-央音，3-国音，4-英皇，0-所有模块
        type: 1, //请求类型：-1-全部，1-最新，2-最热
        column: 0, //所属栏目：1-考级，2-大赛，0-不分栏目
        release_terminal: 2, //发布端口：1-乐考通，2-云音符官网，默认为乐考通
        is_top: 1, //是否置顶：0-否，1-是，-1-全部（不区分是否置顶）
        is_choiceness: -1, //是否精选：0-否，1-是，-1-全部（不区分是否精选）
        page: 1,
        limit: 10,
      }
      this.$http.getArticles(data).then((res) => {
        this.newsListTop = res.list
        this.getSwiper()
        this.$store.commit('hideLoading')
      }).catch(err => {
        console.log(err)
        this.$store.commit('hideLoading')
      })
    },
    getSwiper () {
      setTimeout(() => {
        new Swiper('.swiper-container', {
          loop: true, //无缝轮播
          // 如果需要分页器
          pagination: '.swiper-pagination',
          // 如果需要前进后退按钮
          nextButton: '.swiper-button-next',
          prevButton: '.swiper-button-prev',
          mousewheelControl: true, //开启鼠标滚轮控制
          // 如果需要滚动条
          // scrollbar: '.swiper-scrollbar',
          //如果需要自动切换海报
          // autoplay: {
          //   delay: 10000, //时间 毫秒
          //   disableOnInteraction: false, //用户操作之后是否停止自动轮播默认true
          // },
          autoplay: 5000,
          slidesPerView: 3,
          spaceBetween: 10,
          // loopedSlides: 8,
        })
      })
    },
    moreNews () {
      this.page += 1
      this.getNews()
    }
  },
}